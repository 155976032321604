.all-licenses-background {
  // border: 1px solid red;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .accordion {
    margin-left: 30px;
  }

  .search-label {
    width: 100%;
    margin-bottom: 25px;
  }
  .license-card-offline {
    // background-color: #FFFFFF;
    // border: 1px solid #BCE0FD;
    // padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .hardware-id-field{
    display: contents;
  }
  .license-card {
    background-color: $carbon-license-card-back;
    border: 1px solid $carbon-outline;
    box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.3);
    border-radius: 15px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    button {
      max-height: 38px;
    }

    .add-email-field {
      .form-control {
        border: 1px solid $carbon-blue;
        width: 169px;
        text-align: center;
        padding-left: 12px;
        padding-bottom: 6px;
        margin: auto;
        color: $carbon-blue;
        // opacity: 0.5;
      }
      
      input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $carbon-blue;
      }

      input::-moz-placeholder { /* Firefox 19+ */
        color: $carbon-blue;
      }

      input:-ms-input-placeholder { /* IE 10+ */
        color: $carbon-blue;
      }

      input:-moz-placeholder { /* Firefox 18- */
        color: $carbon-blue;
      }
    }

    .form-control {
      color: $carbon-blue;
      font-weight: bold;
      border: unset;
      background-color: $carbon-license-card-back !important;
      padding-left: unset;
      padding-bottom: unset;
      width: 100%;
    }
  }
}
.react-bootstrap-table .table-bordered {
  border: unset;  
}
.collapse{
  .show {
    margin-right: 15px;
  } 
}

.homepage-top-row {
  margin: auto;
}

.action-icon {
  &:hover {
    cursor: pointer;
  }
}
.custom-toast {
  // position: fixed;
  // top: 1rem;
  // right: 2rem;
  // z-index: 1050; /* Ensure it appears above other elements */
  // max-width: 300px;
  // text-align: center;
  // width: 100%
  position: fixed;
  top: 1%; /* Adjust this value for vertical spacing from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050; /* Ensure it's above other elements */
  max-width: 300px;
  width: 100%;
  text-align: center;
}