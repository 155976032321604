/* SideNav.scss */

/* Ensure consistent box sizing across all elements */
*, *::before, *::after {
  box-sizing: border-box;
}

.nav-wr {
  display: flex;
  flex-direction: column; // Default to column for stacking vertically
  justify-content: space-between; // Ensures top and bottom alignment
  background-color: #181818;
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden; // Prevent horizontal overflow
  overflow-y: hidden; // Hide vertical overflow; handled by .nav
  transition: width 0.3s ease;
  scroll-behavior: smooth;

  /* Media Queries for Responsiveness */
  @media (max-width: 1200px) {
    width: 280px;
  }

  @media (max-width: 1080px) {
    width: 250px;
  }

  @media (max-width: 768px) {
    width: 220px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    position: relative;
  }

  /* Child Elements */
  .nav {
    flex: 1 1 auto; // Allows the nav section to grow and shrink as needed
    overflow-y: auto; // Enable internal vertical scrolling
    overflow-x: hidden; // Prevent horizontal scrolling within nav
    padding: 1rem;
    display: flex;
    flex-direction: column; // Ensure vertical stacking by default
    gap: 0.3rem; // Reduced gap for tighter spacing
    flex-wrap: nowrap; // Prevent flex items from wrapping

    /* Scrollbar Styles */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #2c2c2c;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #555;
      border-radius: 3px;
    }

    .nav-logo {
      display: block;
      margin-bottom: -1rem;
      text-align: left;

      img.logo-img {
        width: 100%;
        max-width: 200px; // Prevent the logo from becoming too large
        height: auto;
        object-fit: contain; // Ensure logo scales appropriately
      }
    }

    .nav-btns {
      display: flex;
      flex-direction: column; // Stack nav-btns vertically by default
      gap: 0.3rem; // Consistent spacing between nav-btns
      width: 100%; // Ensure nav-btns don't exceed sidebar width
      margin-bottom: 0; // Remove any excessive margin

      .nav-header {
        font-size: 1rem;
        color: #ffffff;
        margin-bottom: 0.2rem; // Reduced from 0.5rem to 0.2rem
      
        letter-spacing: 0.05em;

        strong {
          font-weight: bold;
        }
      }

      a.subhead,
      .sign-out-link {
        display: block;
        width: 100%;
        padding: 0.2rem 0;
        margin: 0; // Remove any existing margin
        color: #e0e0e0;
        font-weight: 300 !important; // Adjust font weight as needed
        font-size: 14px;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.2s ease, color 0.2s ease;
        word-wrap: break-word; // Prevent long words from causing overflow
        word-break: break-all; // Further prevent overflow
        box-sizing: border-box; // Ensure padding is included within width

        &:hover {
          // background-color: #333333;
          color: $carbon-blue; // Highlight color on hover
        }

        &.active {
          // background-color: #333333;
          color: $carbon-blue; // Highlight color on hover
        }

        /* Specific styling for sign-out link */
        &.sign-out-link {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            margin-left: 0.5rem;
            font-size: 1rem;
          }
        }
      }
      
    
    }
    /* Social Icons */
    .il-social {
      margin-top: 1rem;
      display: inline-block ;
      a {
       
        margin: 0 0.5rem;
        color: #ffffff;
        font-size: 0.7rem;
        transition: color 0.2s ease;
        max-width: 100%; // Prevent icons from exceeding container width
        box-sizing: border-box; // Ensure padding is included within width

        &:hover {
          color: $carbon-blue !important;
        }

        i {
          pointer-events: none; // Prevent icon from capturing pointer events
        }
      }
    }
  }

  /* User Info Section at the Bottom */
  .user-info-section {
    padding: 1rem;
    background-color: #1f1f1f;
    border-top: 1px solid #333333;
    margin-top: auto; // Pushes this section to the bottom

    .text-carbon-blue {
      color: $carbon-blue !important;
    }

    .nav-divider {
      border: none;
      border-bottom: 1px solid #555555;
      margin-bottom: 1rem;
    }

    .user-info {
      display: flex;
      align-items: center;
      color: #ffffff;
      width: 100%; // Ensure it doesn't exceed container width

      img.user-avatar {
        width: 50px;
        height: 50px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        object-fit: cover;
        max-width: 100%; // Prevent avatar from exceeding container width
      }

      .user-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem; // Add some spacing between avatar and details

        .user-name {
          font-size: 0.9rem;
          font-weight: bold;
          color: #007bff;
          text-transform: uppercase;
          margin-bottom: 0.2rem;
        }

        .user-email {
          font-size: 0.8rem;
          color: #b3b3b3;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word; // Prevent overflow
        }
      }

      .sign-out-link {
        display: flex;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        transition: color 0.2s ease;

        &:hover {
          color: #ff4d4d; // Change to a red shade on hover
        }

        i.fas.fa-sign-out-alt {
          font-size: 1rem;
        }
      }
    }

  }

  /* Responsive Adjustments */
}

/* Media Query for High Resolutions (4K and above) */
@media (min-width: 3840px) {
  .nav-wr {
    width: 300px; // Adjust width as needed for high resolutions
  }

  .nav {
    flex-direction: row; // Change to row for high-res
    gap: 1rem; // Increased gap for horizontal layout
    flex-wrap: nowrap; // Prevent wrapping in high-res

    .nav-logo {
      margin-bottom: 0; // Adjust margins for horizontal layout
      margin-right: 2rem; // Add right margin if needed
    }

    .nav-btns {
      flex-direction: row; // Align nav-btns horizontally
      gap: 1rem; // Increased gap between sections

      .nav-header {
        margin-bottom: 0.2rem;
      }

      a.subhead,
      .sign-out-link {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        white-space: nowrap; // Prevent wrapping in horizontal layout
      }

      /* Keep headers and links stacked within each section */
      &.admin,
      &.connect {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .user-info-section {
    padding: 1rem;
    display: flex; // Align user info horizontally if desired
    align-items: center;

    .user-info {
      flex-direction: row;
      align-items: center;

      .user-details {
        margin-left: 0.5rem;
      }
    }

    .il-social {
      margin-top: 0;
      margin-left: 1rem; // Add left margin if needed
    }
  }
}

/* Existing Media Queries */
@media (max-width: 1080px) {
  .nav-wr {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .nav-wr {
    width: 220px;
  }

  .nav-btns {
    a.subhead,
    .sign-out-link {
      padding: 0.4rem 0.8rem;
      font-size: 0.9rem;
    }

    .nav-header {
      font-size: 0.95rem;
    }
  }

  .user-info-section {
    padding: 0.8rem;

    .user-info {
      img.user-avatar {
        width: 45px;
        height: 45px;
      }

      .user-details {
        .user-name {
          font-size: 0.85rem;
        }

        .user-email {
          font-size: 0.75rem;
        }
      }

      .sign-out-link {
        i.fas.fa-sign-out-alt {
          font-size: 0.9rem;
        }
      }
    }

    .il-social a {
      font-size: 0.7rem; // Adjust font size for social icons
    }
  }
}

@media (max-width: 480px) {
  .nav-wr {
    width: 100%;
    height: auto;
    position: relative;
  }

  .nav {
    padding: 0.8rem;

    .nav-logo img.logo-img {
      max-width: 150px;
    }

    .nav-btns {
      .nav-header {
        font-size: 0.9rem;
      }

      a.subhead,
      .sign-out-link {
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
      }
    }
  }

  .user-info-section {
    padding: 0.6rem;

    .user-info {
      img.user-avatar {
        width: 40px;
        height: 40px;
      }

      .user-details {
        .user-name {
          font-size: 0.8rem;
        }

        .user-email {
          font-size: 0.7rem;
        }
      }

      .sign-out-link {
        i.fas.fa-sign-out-alt {
          font-size: 0.8rem;
        }
      }
    }
  }

  .il-social {
    a {
      font-size: 1rem;
    }
  }
}
/* Hide Sidebar Below 768px */
@media (max-width: 768px) {
  .nav-wr {
    display: none;
  }

  /* Optionally, you can add styles to display your alternative menu */
  .alternative-menu {
    display: block; // Ensure your alternative menu is visible
    /* Add additional styles as needed */
  }
}