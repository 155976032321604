
// .navbar {
//   background-color: $carbon-blue;
//   border-bottom: 2px solid rgba(0, 0, 0, 0.3);
//   min-height: 81px;
//   text-align: left;
 
//   .navbar-nav .nav-link { 
//     color: #ffffff;
//     margin: 0 15px;
//   }

//   .nav-header {
//     color: #ffffff;
//   }

//   .nav-user {
//     font-weight: normal;
//     color: white;
//   }

//   a {
//     align-self: center;
//     margin: 0 15px;
//     padding: 8px 0;
//     color: #ffffff;
    
//     &.dropdown-item {
//       margin: 0;
//       padding: .25rem 1.5rem;
//     }
    
//     &:hover {
//       color: rgba(0,0,0,.7)
//     }
//   }

//   .form-inline {
//     align-self: center;
//   }

//   button {
//     background-color: $carbon-blue;
//     color: #EEE;
//     border: 2px solid #000;
//   }


//   .ha-teal {
//     color: #2E20B1 !important;
//     border-bottom: 1px solid #75FFA4;
//   }

//   .keep-centered {
//     text-align: center;
//   }
// }

// .signout-btn {
//   width: 75%;
//   margin: auto;
// }
/* Ensure alignment for the Navbar and its contents */
// .top-nav .nav-header {
//   text-align: left; /* Align section headers to the left */
//   font-size: 1rem;
//   font-weight: bold;
//   color: #ffffff;
//   text-transform: uppercase;
//   margin-bottom: 0.5rem;
// }

// .top-nav .nav-link {
//   text-align: left; /* Align nav links to the left */
//   color: #ffffff; /* White text for links */
//   padding: 0.5rem 1rem; /* Adjust padding for better spacing */
// }

// .top-nav .nav-link:hover {
//   color: #007bff; /* Change color on hover */
// }

// .top-nav .navbar-collapse {
//   text-align: left; /* Ensure the collapsible container aligns everything left */
//   padding-left: 1rem; /* Add padding for spacing */
// }

// .top-nav .nav {
//   display: flex;
//   flex-direction: column; /* Stack items vertically */
//   align-items: flex-start; /* Align all items to the left */
//   width: 100%; /* Ensure full width */
// }

// /* Sign Out Button */
// .top-nav .btn {
//   text-align: left; /* Align button text */
//   width: 100%; /* Make it span the full width */
//   margin-top: 1rem; /* Add spacing above the button */
// }

// .top-nav .btn:hover {
//   background-color: #0056b3; /* Darker background on hover */
//   color: #ffffff; /* White text */
// }
// /* Add padding to the collapsible dropdown menu */
// .top-nav .navbar-collapse {
//   padding-top: 1rem; /* Add padding at the top */
//   padding-bottom: 1rem; /* Add padding at the bottom */
// }

// /* Ensure consistent spacing for the nav container */
// .top-nav .nav {
//   padding-top: 0.5rem; /* Add some padding above the links */
//   padding-bottom: 0.5rem; /* Add some padding below the links */
// }

// /* Optional: Adjust padding for each nav link */
// .top-nav .nav-link {
//   padding: 0.75rem 1rem; /* Add vertical and horizontal padding */
// }

/* TopNav.scss */

/* Ensure consistent box sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #181818 !important; /* Bootstrap dark bg */

  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  @media (max-width: 550px) {
    padding: 0.3rem;
  }
  .social-links {
    display: flex;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .logo-img {
      width: 60px;
      height: auto;
      margin-right: 8px;

      @media (max-width: 768px) {
        width: 122px;
        margin-right: 6px;
      }

      @media (max-width: 550px) {
        width: 122px;
        margin-right: 4px;
      }
    }

    .brand-text {
      display: flex;
      flex-direction: column;

      .brand-name {
        font-weight: 600;
        font-size: 1.2rem;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }

        @media (max-width: 550px) {
          font-size: 1rem;
        }
      }

      .brand-subtitle {
        font-size: 0.8rem;
        color: #ffffff;

        @media (max-width: 550px) {
          display: none;
        }
      }
    }
  }

  .user-info-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;

    .user-avatar {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border: 2px solid #ffffff;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }

      @media (max-width: 550px) {
        width: 40px;
        height: 40px;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      margin-right: 3px;

      .user-name {
        font-weight: bold;
        color: $carbon-blue;
        font-size: 1rem;

        @media (max-width: 768px) {
          font-size: 0.95rem;
        }

        @media (max-width: 550px) {
          font-size: 0.9rem;
        }
      }

      .user-email {
        font-size: 0.8rem;
        color: #b3b3b3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 550px) {
          display: none;
        }
      }
    }

    .sign-out-link {
      display: flex;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
      transition: color 0.2s ease;
      margin-left: 8px;
      font-size: 0.9rem;

      &:hover {
        color: #ff4d4d; /* Change to a red shade on hover */
      }

      i.fas.fa-sign-out-alt {
        font-size: 1rem;
        margin-right: 4px;

        @media (max-width: 550px) {
          font-size: 0.9rem;
        }
      }

      @media (max-width: 550px) {
        font-size: 0.8rem;
        margin-left: 4px;
      }
    }
  }

  .nav-header {
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    text-transform:unset !important;
    margin-bottom: 0.5rem;

    @media (max-width: 550px) {
      font-size: 0.95rem;
    }
  }

  .nav-link {
    text-align: left;
    color: #e0e0e0 !important;
    padding: 0.5rem 0rem;
    transition: color 0.2s ease;
    font-size:14px !important;
    font-weight:300 !important;
    &:hover {
      color: $carbon-blue !important;
    }

    @media (max-width: 550px) {
      padding: 0.4rem 0;
      font-size: 0.85rem;
    }
  }

  .navbar-collapse {
    text-align: left;
    padding: 1rem 0;

    @media (max-width: 550px) {
      padding: 0.5rem 0;
    }
  }

  .nav {
    width: 100%;

    h5.nav-header {
      margin-top: 1rem;

      @media (max-width: 550px) {
        margin-top: 0.8rem;
      }
    }

    .nav-link {
      padding: 0.5rem 1rem;

      @media (max-width: 550px) {
        padding: 0.4rem 0.6rem;
      }
    }
  }

  .menu-toggle {
    border: none;
    background: none;
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;

    @media (max-width: 550px) {
      font-size: 1.2rem;
    }
  }
  .upvoty-container{
    margin-left:10px;
    
    &a{
      color:white;
    }
  }
}
