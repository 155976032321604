$menu-width: 300px;
$carbon-blue: #05bfd8;
$carbon-blue-hover: #05bfd8;
$carbon-color: #141618;
$carbon-shadow: #626262;
$carbon-highlight: #959596;
$carbon-background-page:#282828;
$carbon-dark-background: #181818;
$carbon-dark-contrast: #1f1f1f;
$carbon-white: #ffffff;
$carbon-text: #e0e0e0;
$carbon-outline: #9b9b9b;
$carbon-license-card-back: #393939;

$carbon-background: $carbon-color;

#root {
  width: 100%;
}

body {
  margin: unset;
  color: $carbon-text;
}

h2 {
  font-size: 2.3rem;
  font-weight: 300;
  color: #fff;
}

.App {
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  background-color: $carbon-background;
  font-family: 'Inter', sans-serif !important;
}

.omni-error {
  position: fixed;
  top: 5vh;
  left: 30vw;
  width: 50vw;
  z-index: 99999;
  border: 4px solid #dc3545;
  box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.2);
  .toast-header {
    color: $carbon-text;
    background-color: $carbon-dark-background;
    border-bottom: 1px solid rgba(0,0,0,.05);
    .close {
      color: $carbon-text;
      opacity: 1;
    }
  }
  .toast-body {
    background-color: $carbon-dark-background;
    color: $carbon-text;
    text-transform: capitalize;
  }
}
.btn {
  border-radius: 12rem !important;
}
// .spinner-grow {
//   display: table;
//   width: 2rem;
//   height: 2rem;
//   vertical-align: text-bottom;
//   background-color: unset;
//   border-radius: 50%;
//   opacity: 0;
//   -webkit-animation: spinner-grow .75s linear infinite;
//   animation: spinner-grow .75s linear infinite;
// }
.omni-success {
  position: fixed;
  top: 5vh;
  left: 30vw;
  width: 50vw;
  z-index: 99999;
  border: 4px solid #28a745;
  box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.2);

  // .toast-header {
  //   background-color: rgba(0,0,0,.03);
  //   border-bottom: 1px solid rgba(0,0,0,.125);
  // }
  .toast-header {
    color: $carbon-text;
    background-color: $carbon-dark-background;
    border-bottom: 1px solid rgba(0,0,0,.05);
    .close {
      color: $carbon-text;
      opacity: 1;
    }
  }
  .toast-body {
    background-color: $carbon-dark-background;
    color: $carbon-text;
    text-transform: capitalize;
  }
}
.btn-outline-primary:disabled{
  color: $carbon-blue;
  border-color: $carbon-blue;
  &:hover {
    color: $carbon-blue;
    border-color: $carbon-blue;
    background-color: unset;
    // opacity: 0.5;
    // background-color: $carbon-blue-hover;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active{
  color: $carbon-text;
  border-color: $carbon-blue;
  background-color: $carbon-blue-hover;
}

.btn-outline-primary:focus{
  box-shadow: 0 0 0 .2rem rgba(5, 192, 217, 0.5);
  
}

.btn-outline-primary{
  color: $carbon-blue;
  border-color: $carbon-blue;
  &:hover {
    color: $carbon-text;
    border-color: $carbon-blue;
    background-color: $carbon-blue-hover;
  }
}

.modal-content {
  background-color: $carbon-dark-background;
  border: 1px solid $carbon-outline;


}

.toast {
  max-width: unset;
}


.form-control{
  background-color: $carbon-dark-contrast;
  color: $carbon-text;
  border: 1px solid $carbon-outline;
}
.form-control:active, .form-control:focus {
  background-color: $carbon-dark-contrast;
  color: $carbon-text;
  border: 1px solid $carbon-outline;
}

.react-datepicker__header {
  background-color: $carbon-dark-background !important;
  color: $carbon-text !important;
}
.react-datepicker__current-month, .react-datepicker__day-name {
  color: $carbon-text !important;
}

.react-datepicker__day--keyboard-selected {
  color: $carbon-text !important;
  background-color: $carbon-blue-hover !important;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    background-color: $carbon-dark-background !important;
    color: $carbon-text !important;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: $carbon-blue-hover !important;
}
.disabled-date {
  color: $carbon-highlight !important;
  // background-color: $carbon-dark-contrast !important;
}
.react-datepicker__day--selected {
  color: $carbon-text !important;
  background-color: $carbon-blue !important;
  span{
    color: $carbon-text !important;
  }
}.react-datepicker__day:hover {
  color: $carbon-text !important;
  background-color: $carbon-blue !important;
  span{
    color: $carbon-text !important;
  }
}
.selectable-date {
  color: $carbon-blue;
  // background-color: $carbon-dark-contrast !important;
}
.react-datepicker {
  background-color: $carbon-dark-contrast !important;
  color: $carbon-text !important;
}

.modal-content {
  margin-left: calc(#{$menu-width} / 2);
  margin-top: 10%;

  @media (max-width: 892px) {
      margin-left: unset;
  }
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.fade.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.app-wrapper {
  display: flex;
  width: 100%;
  background-color: $carbon-background-page;
}

.delegate-download {
  display: flex;
  flex-direction: column;

  @media (min-width: 1790px) {
    flex-direction: row;

    .offline-btn {
      margin-left: 2.6rem;
    }
  }
}

.owner {
  height: 40px;
  background-color: #7DAF1B;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  line-height: 37px;
  text-align: center;
  font-size: 80%;
}

.holder {
  height: 40px;
  background-color: $carbon-blue;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  line-height: 37px;
  text-align: center;
  font-size: 80%;
}

.page-content {
  position: relative;
  border-radius: 3px;
  background-color: $carbon-background-page;
  display: block;
  width: calc(100% - (#{$menu-width}));
  margin: 25px 25px 25px calc(#{$menu-width});
  height: calc(100% - 50px);
  min-height: 95vh;
  padding: 20px;
  // box-shadow: 0px 20px 60px -30px rgba(0, 0, 0, .45);

  @media (max-width: 761px) {
      width: calc(100% - 50px);
      margin: 134px 25px 0 25px;
  }
}
.custom-toast{
  background-color: $carbon-dark-background;
  border: 1px solid $carbon-outline;
}

// React Bootstrap Table Next CSS customizations
.react-bootstrap-table tr.user td {
  overflow-wrap: break-word;
}

.table-bordered td, .table-bordered th {
  border: unset;
  border-bottom: 1px solid $carbon-outline;
}

//override pagination color styles
.pagination {
  
  .active a {
    background-color: $carbon-blue !important;
    color: #ffffff !important;
    border: 1px solid $carbon-blue !important;
 
  }
  .page-link {
    color: $carbon-blue !important;
    background-color: $carbon-background;
  }
  .page-link:hover {
    color: $carbon-text !important;
    background-color: $carbon-blue-hover !important;
    border-color: $carbon-blue-hover !important;
  }
}

.btn-primary {
  background-color: $carbon-blue !important;
  border-color: $carbon-blue !important;

  &:hover {
    background-color: $carbon-blue-hover;
    border-color: $carbon-blue-hover !important;
  }
}

.table-bordered td {
  overflow-wrap: break-word;
}
.text-carbon-blue {
  color: $carbon-blue;
}

.table{
  color:unset;
}
.canvasjs-chart-credit{
  display: none;
}
//import full bootstrap styles


@import "../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "./src/components/pages/Login.scss";
@import "./src/components/pages/SideNav.scss";
@import "./src/components/pages/TopNav.scss";
@import "./src/components/pages/Homepage.scss";
@import "./src/components/pages/Team.scss";
@import "./src/components/pages/AllLicenses.scss";
@import "./src/components/pages/AllUsers.scss";
@import "./src/components/pages/IssueLicense.scss";
