.homepage-background {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .license-card {
    background-color: $carbon-license-card-back !important;
    border: 1px solid $carbon-outline !important;
    padding: 5px 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.3);
    border-radius: 15px;
    button {
      max-height: 38px;
    }

    .add-email-field {
      .form-control {
        border: 1px solid $carbon-blue;
        width: 169px;
        text-align: center;
        padding-left: 12px;
        padding-bottom: 6px;
        margin: auto;
      }
      
      input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $carbon-blue;
      }

      input::-moz-placeholder { /* Firefox 19+ */
        color: $carbon-blue;
      }

      input:-ms-input-placeholder { /* IE 10+ */
        color: $carbon-blue;
      }

      input:-moz-placeholder { /* Firefox 18- */
        color: $carbon-blue;
      }
    }

    // friendly label
    .form-control {
      color: $carbon-blue;
      font-weight: bold;
      border: unset;
      background-color: $carbon-license-card-back;
      padding-left: unset;
      padding-bottom: unset;
      width: 100%;
    }
    //.form-control disabled
    .form-control:disabled
    {
      border-color: $carbon-blue;
      //set to a dulled color like disabled
      opacity: 0.5;
    }
    .form-control:disabled::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $carbon-blue;
      opacity: 1; /* Firefox */
    }
  }

  // React Table Mobile CSS
  @media only screen and 
    (max-width: 760px), 
    (min-device-width: 768px) and 
    (max-device-width: 1024px)  {

    th {
      padding-left: 50%;
      border: 1px solid red;
    }

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
    
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    
    tr { border: 1px solid #ccc; }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50% ;
    }
    
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 25%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
    
    /*
    Label the data
    */
    td:nth-of-type(1):before { content: "UUID"; }
    td:nth-of-type(2):before { content: "NAME"; }
    td:nth-of-type(3):before { content: "PHONE"; }
    td:nth-of-type(4):before { content: "EMAIL"; }
    td:nth-of-type(5):before { content: "# LICENSES"; }
    
    #text {
      padding-top: 10px;
    }

    
  }

  .reset-expansion-style:before {
    display: none;
  }
}

// makes any disabled field return to WHITE instead of a darker grey background.
.form-control:disabled {
  background-color: unset;
}

.tooltip-inner {
  max-width: 350px;
  /* If max-width does not work, try using width instead */
  width: fit-content; 
  text-align: left;
}

.collapse{
  .show {
    margin-right: 15px;
  } 
}

.download-background {
  background-color: $carbon-license-card-back;
  // color: #505050;
  border: 1px solid $carbon-outline !important;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.3);

  .download-help-me {
    color: $carbon-blue
  }

  // .download-form-description {
  //   // // padding-left: unset;
  //   // display: flex;  
  //   // flex-wrap: wrap;
  // }

  .download-input-title {
    margin-top: 12px;
    height: 26px;
  }

  .download-button {
    text-align: center;
  }
}

.collapse {
  .show div {
    background-color: white;
  }
}

.homepage-top-row {
  margin: auto;

  h1 {
    text-transform: uppercase;
  }
}