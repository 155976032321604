.homepage-background {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .license-card {
    background-color: #FFFFFF;
    border: 1px solid #BCE0FD;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .hardware-id-field{
    display: contents;
  }
}

.react-bootstrap-table {
  tr.user {
    td {
      cursor: pointer;
    }
  }
}

.homepage-top-row {
  margin: auto;
}