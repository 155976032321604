.bootstrap-iso {
  width: 100%;
}

.issue-license-background {
  form {
    width: 50%;
  }
}

.billing-modal-title {
  font-size: 1.2rem !important;
  font-weight: 600;
}

.license-list-container {
  .license-confirmation {
    height: 95vh;
    width: auto;
    justify-content: center;
    flex-direction: column;
    .license-list-title {
      display: inline-block;
      text-align: center;
    }
    .license-list {
      width: 400px;
      list-style: none;
      font-weight: 600;
      font-size: 1.3rem;
    }
  }
  .license-links {
    text-align: center;
    color: #007BFF;
    font-size: 1.3rem;
    a:first-child {
      margin-right: 100px;
      &:hover {
        text-decoration: underline;
        color: #0056B3;
        cursor: pointer;
      }
    }
  }
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: #F6F6F6;
  top: 0;
  left: 0;
}

.isactiveField {
  width: 100%;
  position: relative;
  label {
    display: inline;
  }
  div {
    transform: scale(.5);
    width: 20%;
    position: absolute;
    top: -7px;
    left: 20px;
  }
  position: relative;
}
