// html,
// body {
//   height: 100%;
// }

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: $carbon-color;
  font-family: 'Inter', sans-serif !important;
  
}
.loginCol{
  @media (min-width: 3840px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 15%;
  }
  @media (min-width: 3440px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 15%;
  }
  @media (min-width: 1200px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 40%;
  }
  @media (min-width: 576px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 67%;
  }
}
.login-top img {
  width: 22%;
  margin-top: 30px;
  
}
.login-top{
  @media (max-width: 830px) {
      width: calc(100% - 50px);
      // margin: 134px 25px 0 25px;
  }
}
.login-top img{
    @media (max-width: 761px) {
      width: calc(100% - 50px);
      // margin: 134px 25px 0 25px;
  }
}
.loginContainer{
  // height:100% !important;
  height: 100px;
  position: absolute;
  top: -400px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.title {
  font-size: 30px;
  // font-family: Nunito, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
  line-height: 1em;
  color:#fff;
}

.btn-social {
  position: relative;
  padding-left: 57px !important;
  text-align: left;
  white-space: nowrap;
  // width: 100%;
  font-size: 12px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
div.card-body>button {
  background: #03AED2 !important;
  line-height: 20px;
  padding: 12px 25px 12px 73px !important;
  color: white !important;
  border: 2px solid white !important;
  text-transform: none;
  font-family: 'Inter', sans-serif !important;
  font-size:14px;
  letter-spacing: 1.5px !important;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border-radius: 6px;
  @media (max-width: 820px) {
    padding: 12px 25px 12px 85px !important;
    letter-spacing: 1px !important;
  }
  @media (max-width: 768px) {
      padding: 12px 25px 12px 75px !important;
      letter-spacing: 1px !important;
      font-size: 9px !important;
  }
  @media (max-width: 375px) {
      padding: 12px 25px 12px 62px !important;
      letter-spacing: 1px !important;
      font-size: 9px !important;
  }
}
div.card-body>button:hover {
  background: #037fd2 !important;
  color: white !important;
  border: 2px solid white !important;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border-radius: 6px;
}
div.card-body>button>i.custom_logo {
  display: inline-block;
  position: absolute;
  width: auto;
  height: auto;
  border: 2px solid #fff;
  border-radius: 6px;
  left: -2px;
  top: -2px;
  bottom: -2px;
  background-color: #222;
  background: url('/img/loginlogobg.png');
  background-size: cover;
}
div.card-body>button>i.custom_logo:before {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-19px);
  margin: 0 5px;
  background-image: url("/img/il.svg");
}
